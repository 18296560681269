import React from "react";
import styled from "styled-components";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors, Gradient } from "~/components/ThemeVariables";
import ExternalLink from "~/components/common/ExternalLink";
import DiscordIcon from "~/components/common/icons/DiscordIcon";
import TelegramIcon from "~/components/common/icons/TelegramIcon";
import TwitterIcon from "~/components/common/icons/TwitterIcon";
import externalLinks from "~/data/dclimate.json";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    text-align: center;

    a {
        text-decoration: none;
    }
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 22px;
    color: ${Colors.ELECTRIC_BLUE};

    span {
        padding: 2px 4px;
        color: ${Colors.BLACK};
        background: ${Colors.ELECTRIC_BLUE};
    }

    ${breakpointIpad(`
        font-size: 24px;
        line-height: 33px;
    `)}
`;

const Border = styled.div`
    border-radius: 10px;
    padding: 2px;
    width: 100%;
    max-width: 540px;
    background: ${Gradient.RADIAL_BG_BORDER};
`;

const Background = styled.article`
    border-radius: 10px;
    padding: 32px 16px;
    display: flex;
    justify-content: center;
    background: ${Gradient.LINEAR};
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    place-items: center;
    width: 100%;
    max-width: 362px;
`;

const SocialItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 62px;
    color: ${Colors.WHITE};
`;

const Icon = styled.div`
    width: 40px;
`;

const JoinSection = (): JSX.Element => (
    <Section>
        <Title>
            Join the dClimate <span>community</span>
        </Title>
        <Border>
            <Background>
                <Grid>
                    <ExternalLink to={externalLinks.social.twitter}>
                        <SocialItem>
                            <Icon>
                                <TwitterIcon />
                            </Icon>
                            <p>Twitter</p>
                        </SocialItem>
                    </ExternalLink>
                    <ExternalLink to={externalLinks.social.telegram}>
                        <SocialItem>
                            <Icon>
                                <TelegramIcon />
                            </Icon>
                            <p>Telegram</p>
                        </SocialItem>
                    </ExternalLink>
                    <ExternalLink to={externalLinks.social.discord}>
                        <SocialItem>
                            <Icon>
                                <DiscordIcon />
                            </Icon>
                            <p>Discord</p>
                        </SocialItem>
                    </ExternalLink>
                </Grid>
            </Background>
        </Border>
    </Section>
);

export default JoinSection;
