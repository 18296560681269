import React from "react";
import styled from "styled-components";

import { breakpointIpad, breakpointIphone } from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";

import RainforestLogo from "./rainforest.inline.svg";
import ClimatecollectiveLogo from "./climatecollective.inline.svg";
import ChainlinkLogo from "./chainlink.inline.svg";
import NaturetechLogo from "./naturetech.inline.svg";
import ProtocollabsLogo from "./protocollabs.inline.svg";
import SolidworldLogo from "./solidworld.inline.svg";
import TruflationLogo from "./truflation.inline.svg";
import ClimatechainLogo from "./climatechain.inline.svg";
import OfpLogo from "./ofp.inline.svg";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 30px;

    ${breakpointIpad(`
        font-size: 16px;
        line-height: 22px;
    `)}

    color: ${Colors.WHITE};
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 50px;
    color: ${Colors.WHITE};

    ${breakpointIpad(`
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 30px;
    `)}
`;

const LogoGrid = styled.div`
    width: 100%;
    max-width: 1024px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 44px;
    place-items: center;

    & svg {
        height: 64px;
        width: auto; /* Maintain the aspect ratio */
    }

    ${breakpointIpad(`
        grid-template-columns: repeat(2, 1fr);
        max-width: 624px;
        column-gap: 16px;

        & svg {
        height: 56px;
    }
    `)}

    ${breakpointIphone(`
        grid-template-columns: repeat(1, 1fr);
        max-width: auto;
        row-gap: 24px;
    }
    `)}
`;

const PartnershipSection = (): JSX.Element => (
    <Section>
        <Title>Partnerships & Collaboration</Title>
        <LogoGrid>
            <RainforestLogo />
            <ClimatecollectiveLogo />
            <ChainlinkLogo />
            <NaturetechLogo />
            <ProtocollabsLogo />
            <SolidworldLogo />
            <TruflationLogo />
            <ClimatechainLogo />
            <OfpLogo />
        </LogoGrid>
    </Section>
);

export default PartnershipSection;
