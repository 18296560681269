import React, { FC, ReactElement } from "react";
import styled from "styled-components";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors, FontFamily } from "~/components/ThemeVariables";
import ExternalLink from "~/components/common/ExternalLink";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: ${FontFamily.SOURCE};
    color: ${Colors.WHITE};

    a {
        color: inherit;
        text-decoration: initial;
    }

    ${breakpointIpad(`
        font-size: 15px;
        line-height: 17px;
    `)}
`;

const PhotoContainer = styled.div`
    max-width: 200px;
`;

const Name = styled.p`
    text-transform: uppercase;
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 6px;

    ${breakpointIpad(`
        font-size: 16px;
        line-height: 18px;
    `)}
`;

const Description = styled.p`
    margin-bottom: 14px;
    max-width: 300px;
`;

interface ProfileProps {
    /** Photo element of profile */
    Photo: ReactElement;
    name: string;
    link?: string;
    socialHandle?: string;
    description?: string;
}

const Profile: FC<ProfileProps> = ({
    Photo,
    link = "",
    socialHandle = "",
    description = "",
    name,
}): JSX.Element => (
    <Container>
        <PhotoContainer>{Photo}</PhotoContainer>
        <Name>{name}</Name>
        {description && <Description>{description}</Description>}
        {socialHandle && (
            <ExternalLink to={link}>
                <span>@{socialHandle}</span>
            </ExternalLink>
        )}
    </Container>
);

export default Profile;
