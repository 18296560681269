import React from "react";
import { Helmet } from "react-helmet";

import Layout from "~/components/Layout";
import AdvisorSection from "~/components/about/AdvisorSection";
// import DiagramSection from "~/components/about/DiagramSection";
// import FounderSection from "~/components/about/FounderSection";
import JoinSection from "~/components/about/JoinSection";
import PartnershipSection from "~/components/about/PartnershipSection";

const CommunityPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - About</title>

            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta property="og:title" content="dClimate - About" />
            <meta property="og:type" content="website" />

            <meta property="og:url" content="https://dclimate.net" />
            <meta
                name="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1200x630.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x960.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="960" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x1080.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta property="og:site_name" content="dClimate" />

            <meta
                property="og:video"
                content="https://www.youtube.com/watch?v=nHOXUVYwWL4"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="dClimate - About" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:image"
                content="https://dclimate.net/img/meta/twitter_summary_card.png"
            />
            <meta name="twitter:image:alt" content="get data. share data." />
        </Helmet>
        <Layout>
            {/* <FounderSection /> */}
            <AdvisorSection />
            <PartnershipSection />
            <JoinSection />
            {/* <DiagramSection /> */}
        </Layout>
    </>
);

export default CommunityPage;
