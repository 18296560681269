import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";
import externalLinks from "~/data/external.json";
import Profile from "../Profile";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 30px;
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 50px;
    color: ${Colors.WHITE};

    ${breakpointIpad(`
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 30px;
    `)}
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 45px;
    row-gap: 30px;

    ${breakpointIpad(`
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    `)}
`;

const AdvisorSection = (): JSX.Element => (
    <Section>
        <Title>dClimate’s Advisory Board</Title>
        <Grid>
            <Profile
                name="Amb. J. Peter Pham"
                description="Distinguished Fellow at the Atlantic Council, former U.S. Special Envoy for the Sahel Region of Africa"
                socialHandle="DrJPPham"
                link={externalLinks.social.pham}
                Photo={
                    <StaticImage
                        src="./peter-pham.png"
                        alt="Peter Pham Photo"
                        placeholder="blurred"
                    />
                }
            />
            <Profile
                name="Mark Cuban"
                description="Investor, Entrepreneur, Star of ABC’s Shark Tank, Owner of the NBA’s Dallas Mavericks"
                socialHandle="mcuban"
                link={externalLinks.social.cuban}
                Photo={
                    <StaticImage
                        src="./mark-cuban.png"
                        alt="Mark Cuban Photo"
                        placeholder="blurred"
                    />
                }
            />
            <Profile
                name="Sergey Nazarov"
                description="Co-Founder of Chainlink"
                socialHandle="SergeyNazarov"
                link={externalLinks.social.nazarov}
                Photo={
                    <StaticImage
                        src="./sergey-nazarov.png"
                        alt="Sergey Nazarov Photo"
                        placeholder="blurred"
                    />
                }
            />
            <Profile
                name="D.J. Mbenga"
                description="2x NBA Champion from the DRC & Prominent Congolese Philanthropist"
                socialHandle=""
                link={externalLinks.social.nazarov}
                Photo={
                    <StaticImage
                        src="./dj-mbenga.png"
                        alt="DJ Mbenga Photo"
                        placeholder="blurred"
                    />
                }
            />
        </Grid>
    </Section>
);

export default AdvisorSection;
